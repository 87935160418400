@import "./pre.scss";
@import "./theme.scss";
@import "./atom.scss";
@import "./md.scss";
@import "./syntax.scss";


:root {
  --max-w: 780px;
  --font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

html {
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  box-sizing: border-box;
  border-collapse: collapse;
}

body {
  margin: 0 16px;
  font-family: var(--font);
  font-weight: 400;
  line-height: 1.6;
  background-image: var(--back-image);
  background-color: var(--back);
  color: var(--text);
  transition-property: background-color, border-color, color;
  transition-duration: 0.5s;
}

main {
  animation: showup 0.7s;
}

main p a {
  color: var(--prime);
}

main p a:hover {
  text-decoration: underline;
}

main .search > input {
  width: 100%;
  padding: .5em;
  font-size: large;
  border: 2px solid var(--grid);
  border-radius: 2px;
  background-color: transparent;
  outline: none;
}

@keyframes showup {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@media (min-width: 640px) {
  body {
    margin-top: calc(1rem + 2vh);
  }
}
