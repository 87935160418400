.chroma {
  margin: .5em 0;
  background-image: var(--back-image);
  background-color: var(--code-back);
  border-radius: 2px;
}

.chroma code {
  color: var(--code-text);
}

/* LineTableTD */
.chroma .lntd {
  vertical-align: top;
  padding: 0;
  margin: 0;
  border: 0;
}

.chroma .lntable tbody {
  border: 0;
}

.chroma .lntable td:nth-child(2) {
  width: 100%;
}

.chroma .lnt,
.chroma .line {
  display: block;
  line-height: 1.3;
}

/* LineNumbersTable */
.chroma .lnt {
  color: var(--code-line-number);
  padding-left: .9em;
  padding-right: 1em;
  text-align: right;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.chroma .hl .lnt {
  display: inline;
}

/* Keyword */
.chroma .kc,
.chroma .kd,
.chroma .kn,
.chroma .kp,
.chroma .kr,
// .chroma .kt,
.chroma .k,
.chroma .si {
  color: var(--code-keyword);
}

.chroma .na {
  color: var(--code-attribute);
}

// .chroma .n,
.chroma .nb,
.chroma .bp,
// .chroma .nc,
.chroma .no,
.chroma .nd,
.chroma .ni,
.chroma .ne,
// .chroma .nf,
.chroma .fm,
.chroma .nl,
.chroma .nn {
  color: var(--code-keyword);
}

/* NameProperty */
.chroma .py,
.chroma .nt,
.chroma .nv,
.chroma .vc,
.chroma .vg,
.chroma .vi,
.chroma .vm {
  color: var(--code-keyword);
}

/* LiteralString */
.chroma .s,
.chroma .sa,
.chroma .sb,
.chroma .sc,
.chroma .dl,
.chroma .sd,
.chroma .s2,
.chroma .se,
.chroma .sh,
.chroma .sx,
.chroma .sr,
.chroma .s1,
.chroma .ss,
.chroma .cpf {
  color: var(--code-literal-string);
}

/* LiteralNumber */
.chroma .m,
.chroma .mb,
.chroma .mf,
.chroma .mh,
.chroma .mi,
.chroma .il,
.chroma .mo {
  color: var(--code-literal-number);
}

/* Comment */
.chroma .c,
.chroma .ch,
.chroma .cm,
.chroma .c1,
.chroma .cs {
  color: var(--code-comment);
}

.chroma .nb,
.chroma .kt {
  color: var(--code-builtin-type);
}

.chroma .fm,
.chroma .nf {
  color: var(--code-function);
}


.chroma .cp {
  color: var(--code-meta);
}

.chroma .hl {
  display: block;
  background-color: var(--code-highlighted-line);
}
